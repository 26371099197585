<template>
  <div>
    <Navbar/>
    <div class="section big-55-height over-hide z-bigger" style="height:250px">

      <div class="parallax parallax-top" style="background-image: url('assets/img/image5.jpeg')"></div>
      <div class="dark-over-pages"></div>

      <div class="hero-center-section pages">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 parallax-fade-top">
              <div class="hero-text">We have {{hotels.length}} hotels available for you</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section padding-top-bottom z-bigger" ref="search">
      <div class="section z-bigger">
        <div class="container-fluid px-5">
          <div class="row">
            <div class="col-lg-8 mt-4 mt-lg-0 result-items">
              <!--                            <template v-if="isLoading">-->
              <!--                                <div class="vld-parent">-->
              <!--                                    <h5>Searching for Available Hotels...... <i class="fas fa-spinner fa-spin"></i></h5>-->
              <!--                                </div>-->
              <!--                            </template>-->
              <template v-if="hotels.length > 0">
                <div class="row mb-2" v-for="(hotel, index) in hotels" :key="index">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="room-box background-grey">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                          <div class="room-name">
                             <span>
                                <s class="mr-2" v-if="hotel.originalPrice">
                                    <em v-if="hotel.currency">{{get_currency_symbol(hotel.currency)}}</em>{{ get_round_up_price(hotel.originalPrice) }}
                                </s>
                                <em v-if="hotel.currency">{{get_currency_symbol(hotel.currency)}}</em>{{ get_round_up_price(hotel.price) }}
                            </span>
                          </div>
                          <div class="room-per text-warning">
                            <i class="fa fa-star text-warning" v-for="count in Number.parseInt(hotel.rating)"
                               :key="'rating_'+count"></i>
                          </div>
<!--                          <div class="room-price">
                            &lt;!&ndash;                                                        <span v-if="hotel.createdBy !== 'havemyroom'">&ndash;&gt;
                            &lt;!&ndash;                                                            <s class="mr-2"&ndash;&gt;
                            &lt;!&ndash;                                                               v-if="hotel.originalPrice">&ndash;&gt;
                            &lt;!&ndash;                                                                {{ get_currency_symbol(hotel.currency) }}{{}} </s>&ndash;&gt;
                            &lt;!&ndash;                                                            {{ get_currency_symbol(hotel.currency) }}{{ hotel.price }}&ndash;&gt;
                            &lt;!&ndash;                                                        </span>&ndash;&gt;


                          </div>-->
                          <div class="img-container img-landscape">
                            <img :src="hotel.picturesURL[0]" alt="">
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                          <div class="room-box-in">
                            <h5 @click="update_single_room(hotel)" class="" style="cursor: pointer">{{ hotel.name }}</h5>
                            <p @click="update_single_room(hotel)" class="mt-3" v-if="hotel.hotelDescription" style="cursor: pointer">{{
                                hotel.hotelDescription.substr(0, 200)
                              }}...</p>
                            <div class="room-icons mt-4 pt-4">
                              <img src="assets/img/5.svg" alt="">
                              <img src="assets/img/2.svg" alt="">
                              <img src="assets/img/3.svg" alt="">
                              <a href="javascript:void(0)" @click="update_single_room(hotel)">full info</a>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>

                  </div>
                </div>
              </template>

              <div class="row" v-else>
                <div class="col-lg-6">
                  <h5>{{ feedBack }}</h5>
                </div>
              </div>
            </div>

            <div class="col-lg-4 order-first order-lg-last">
              <div class="section background-dark p-4">
                <div class="row">
                  <div class=" col-12" id="light">
                    <vue-google-autocomplete
                      ref="address"
                      id="map"
                      v-model="address_text"
                      placeholder="Enter Your Destination"
                      v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>
                  </div>
                  <div class="col-12">
                    <div class="input-group">
                      <div class="col-12 pt-4 px-0">
                        <HotelDatePicker class="myDate"
                                         :startDate="new Date()"
                                         :displayClearButton=false
                                         @period-selected="setDate"
                                         :i18n="i18n"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div class="col-12 pt-4">
                        <select name="adults" v-model.number="adultsCount" id="dark" class="wide">
                          <option value="">adults</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                      <div class="col-12 pt-4">
                        <select name="children" v-model="childAge" id="dark" class="wide">
                          <option value="">children</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2 mb-1" v-if="childrenList.length > 0">
                    <div v-for="(age, index) in childrenList" :key="index">

                      <!--                  <div class="col-2"> &lt;!&ndash;- -&ndash;&gt;</div>-->
                      <div class="col-12"><p style="color: white; font-weight:bold;  font-size:14px"> Child
                        {{ index + 1 }} Age</p>
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-primary"
                            @click="decrementNumber(index, age)"
                            :disabled="childrenList[index] === 0"
                          >
                            <i class="fa fa-minus"></i>
                          </button>
                          <input type="text" style="max-width: 50px; background: white" v-model="childrenList[index]"
                                 disabled/>
                          <button
                            type="button"
                            class="btn  btn-primary"
                            @click="incrementNumber(index, age)"
                            :disabled="childrenList[index] === 17"
                          >
                            <i class="fa fa-plus"></i>
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 pt-5">
                    <a class="booking-button" style="background-color:goldenrod" @click.prevent="searchHotels"
                       href="javascript:void(0)"><span v-if="isLoading"> <font-awesome-icon icon="spinner" size="1x"
                                                                                            spin/></span><span v-else>Search</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <Footer/>
  </div>
</template>

<script>
import Navbar from "../Navbar";
import Footer from "../Footer";
import {mapGetters} from 'vuex'

import HotelDatePicker from 'vue-hotel-datepicker'
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';

import {Toast} from "../../utils/mixins/methodMixins";

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import methodMixins from "../../utils/mixins/methodMixins";
import dayjs from "dayjs";


export default {
  name: 'HotelSearchResult',
  data() {
    return {
      address_text: '',
      feedBack: 'Search Hotel',
      checkIn: '',
      checkOut: '',
      toggle: false,
      adultsCount: '',
      childrenList: [],
      childAge: '',
      longitude: '',
      latitude: '',
      i18n: {
        "night": "Night",
        "nights": "Nights",
        "week": "week",
        "weeks": "weeks",
        "day-names": ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"],
        "check-in": "When",
        "check-out": "When",
        "month-names": [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        "tooltip": {
          "halfDayCheckIn": "Available CheckIn",
          "halfDayCheckOut": "Available CheckOut",
          "saturdayToSaturday": "Only Saturday to Saturday",
          "sundayToSunday": "Only Sunday to Sunday",
          "minimumRequiredPeriod": "%{minNightInPeriod} %{night} minimum.",
        },
      }
    }
  },
  mixins: [methodMixins],
  components: {
    Navbar,
    Footer,
    VueGoogleAutocomplete,
    HotelDatePicker,
  },
  computed: {
    ...mapGetters({
      hotels: 'hotel/getHotels',
      isLoading: 'room/room_is_loading',
      search_params: 'room/get_search_params'
    })
  },
  methods: {
    async update_single_room(hotel) {
      await this.$store.commit('hotel/setActiveHotel', hotel)
      await this.$router.push({name: 'HotelInfo'})
    },

    getAddressData: function (addressData) {

      this.latitude = addressData.latitude
      this.longitude = addressData.longitude
    },
    async searchHotels() {

      if (!this.validateForm()) {
        return
      }

      let search_params = {
        latitude: this.latitude,
        longitude: this.longitude,
        checkIn: this.checkIn,
        checkOut: this.checkOut,
        adultsCount: this.adultsCount,
        childrenList: this.childrenList
      }

      await this.$store.dispatch('room/searchForRooms', search_params)

      if (this.$store.getters['room/room_has_error']) {
        this.$store.commit('room/reset')
        this.$forceUpdate();
      } else if (this.$store.getters['room/room_is_empty']) {
        this.feedBack = 'No available hotels found in specified location'
        this.$store.commit('room/reset')
        this.$forceUpdate();
      }
    },
    validateForm() {
      if (!this.checkIn || !this.checkOut) {
        Toast.fire({
          icon: 'info',
          title: 'Select Check In and Check Out Dates'
        })
        return false
      } else if (!this.latitude || !this.longitude) {
        Toast.fire({
          icon: 'info',
          title: 'Please Select a Location'
        })
        return false
      } else if (!this.adultsCount) {

        Toast.fire({
          icon: 'info',
          title: 'Please Select number of Adult'
        })
        return false
      }


      return true
    },
    incrementNumber(index) {
      this.$nextTick().then(() => {
        // Add the component back in
        this.childrenList[index]++
        this.$forceUpdate();
      });

    },
    decrementNumber(index) {
      this.$nextTick().then(() => {
        // Add the component back in
        this.childrenList[index]--
        this.$forceUpdate();
      });

    },
    setDate(e, checkin, checkout) {
      this.checkIn = checkin;
      this.checkOut = checkout
    }

  },
  watch: {
    'childAge': function (newValue) {
      if (newValue === '') {
        this.childrenList = new Array()
      } else if (parseInt(newValue) !== this.childrenList.length) {
        var age = new Array(parseInt(newValue)).fill(0)
        this.childrenList = age
      }
    }
  },
  mounted() {
    if (Object.prototype.hasOwnProperty.call(this.search_params, 'childrenList')) {
      this.childrenList = this.search_params.childrenList
      this.adultsCount = this.search_params.adultsCount
      this.childAge = this.search_params.select_child_age
      this.address_text = this.search_params.address_text
      this.latitude = this.search_params.latitude
      this.longitude = this.search_params.longitude
      this.checkIn = this.search_params.checkIn
      this.checkOut = this.search_params.checkOut
      this.i18n["check-in"] = dayjs(this.search_params.checkIn).format("YYYY-MM-DD")
      this.i18n["check-out"] = dayjs(this.search_params.checkOut).format("YYYY-MM-DD")

    }
    this.$nextTick(() => {
      // this.$refs.address.focus()
    })
  }
}
</script>
<style scoped>
#light input::-webkit-input-placeholder,
#light-datepicker-1 input::-webkit-input-placeholder {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#light input:-moz-placeholder,
#light input:-moz-placeholder {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

#light input:-ms-input-placeholder,
#light input:-ms-input-placeholder {
  color: white;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}



#light label,
#light label {
  display: block;
  color: white;
}

#light input,
#light input {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  line-height: 1.2;
/*  text-transform: uppercase; */
  letter-spacing: 1px;
  font-weight: 300;
  text-align: left;
  color: #fff;
  border: solid 1px rgba(255, 255, 255, .1);
  outline: none;
 /* cursor: pointer; */
  background-color: transparent;
  transition: border-color .2s ease-out;
}


#dark {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 8px;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  text-align: left;
  color: #fff;
  border: solid 1px rgba(255, 255, 255, .1);
  outline: none;
  cursor: pointer;
  background-color: transparent;
  transition: border-color .2s ease-out;
}

#dark option {
  color: white;
  background-color: black;
}

.myDate {
  /*min-width: 450px;*/
}

.img-container {
  width: 350px;
  border: none;
  overflow: hidden;
}

.img-landscape {
  height: 200px;
}

.hero-text {
  font-size: 2em;
}

.result-items {
  height: 700px;
  max-height: 700px;
  overflow-y: scroll
}

.room-box .img-container {
  width: 100%;
}

.room-name {
  background-color: rgba(0, 0, 0, .5);
  padding: 2px
}

.room-box-in h5 {
  font-size: 1.3em;
}
em{
    font-style: normal;
}

</style>
